import "./components/Style/App.css";
import Title from "./components/Title";
import TopBranches from "./components/TopBranches";
import Cradle from "./components/Cradle";
import BottomContainer from "./components/BottomContainer";
import BottomBranches from "./components/BottomBranches";
import CardBackground from "./components/CardBackground";

function App() {
  return (
    <div className="App">
      <TopBranches />
      <Title />
      <Cradle />
      <BottomBranches />
      <BottomContainer />
      <CardBackground />
    </div>
  );
}

export default App;
