import { useState } from "react";
import "../Style/RSVP.css";

function RSVP() {
  const [enteredFullName, setFullName] = useState("");
  const nameChangeHandler = (event) => {
    setFullName(event.target.value);
  };

  const [enteredPhone, setPhone] = useState("");
  const phoneChangeHandler = (event) => {
    setPhone(event.target.value);
  };

  const [enteredEmail, setEmail] = useState("");
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  const [enteredGroupSize, setGroupSize] = useState("");
  const groupChangeHandler = (event) => {
    setGroupSize(event.target.value);
  };

  const submitRSVP = (event) => {
    event.preventDefault();

    const rsvpForm = {
      fullName: enteredFullName,
      phone: enteredPhone,
      email: enteredEmail,
    };
    console.log(rsvpForm);
  };

  return (
    <div className="formCnt">
      <form onSubmit={submitRSVP}>
        <div className="formLabel">
          <label className="formItem">Full Name: </label>
          <input
            className="formItem"
            type="text"
            id="fullName"
            name="fullName"
            value={enteredFullName}
            onChange={nameChangeHandler}
          />
        </div>
        <div className="formLabel">
          <label className="formItem">Group Size: </label>
          <input
            className="formItem"
            type="text"
            id="groupSize"
            name="groupSize"
            value={enteredGroupSize}
            onChange={groupChangeHandler}
          />
        </div>
        <div className="formLabel">
          <label className="formItem">Phone: </label>
          <input
            className="formItem"
            type="text"
            id="phone"
            name="phone"
            value={enteredPhone}
            onChange={phoneChangeHandler}
          />
        </div>
        <div className="formLabel">
          <label className="formItem">Email: </label>
          <input
            className="formItem"
            type="text"
            id="email"
            name="email"
            value={enteredEmail}
            onChange={emailChangeHandler}
          />
        </div>

        <button type="submit">Submit RSVP</button>
      </form>
    </div>
  );
}

export default RSVP;
