import "./Style/BottomContainer.css";
import "./BtmCnt/Information";
import Information from "./BtmCnt/Information";
import RSVP from "./BtmCnt/RSVP";
import React, { useState } from "react";

function BottomContainer() {
  let currentPageSet = "Home";

  const showFormPage = () => {
    if (currentPageSet === "Home") {
      currentPageSet = "RSVP";
      setCurrentPage(
        <div>
          <RSVP />
          <button className="rsvpBtn" onClick={showFormPage}>
            Baby Shower Information
          </button>
        </div>
      );
    } else {
      currentPageSet = "Home";
      setCurrentPage(
        <div>
          <Information />
          <div className="BtnContainer">
            <button className="rsvpBtn" onClick={showFormPage}>
              - RSVP -
            </button>
          </div>
        </div>
      );
    }
  };

  const [currentPage, setCurrentPage] = useState(
    <div>
      <Information />
      <div className="BtnContainer">
        <button className="rsvpBtn" onClick={showFormPage}>
          - RSVP -
        </button>
      </div>
    </div>
  );

  return (
    <div className="BottomContainer">
      {currentPage}
      <div className="BtnContainer" on>
        <a href="https://www.amazon.com/" target="_blank">
          <button className="regBtn">Registry</button>
        </a>
      </div>
    </div>
  );
}

export default BottomContainer;
