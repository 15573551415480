import "./Style/BottomBranches.css";

function BottomBranches() {
  return (
    <div className="btmCnt">
      <div className="BtmBranches"></div>
    </div>
  );
}

export default BottomBranches;
