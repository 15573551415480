import "./Style/Title.css";

function Title() {
  return (
    <div className="Title">
      <h1>Its a Girl!</h1>
    </div>
  );
}

export default Title;
