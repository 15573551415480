import "../Style/Information.css";

function Information() {

  return (
    <div className="InformationCnt">
      <div className="EventInfo">JOIN US FOR A BABY SHOWER HONORING</div>
      <div className="names">AIXA & JOVANI</div>
      <div className="EventInfo">SATURDAY, MARCH 5TH AT 4 PM</div>
      <div className="EventInfo">B&B BUILDING</div>
      <div className="EventInfo">DIAPER RAFFLE (ANY SIZE)</div>
      <div className="EventInfo">FOR A CHANCE TO WIN A PRIZE</div>
    </div>
  );
}

export default Information;
